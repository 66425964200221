<template>
  <div>
    <b-message v-if="sortedCoupons.length" type="is-dark">
      <apart class="is-paddingless">
        <p>Create a new coupon.</p>
        <button
          class="button is-dark is-rounded is-outlined"
          @click="addCoupon"
        >
          Create coupon
        </button>
      </apart>
    </b-message>

    <loading v-if="loading" />

    <div v-else>
      <b-table
        ref="table"
        :data="sortedCoupons"
        :mobile-cards="true"
        :paginated="
          !!sortedCoupons.length &&
            sortedCoupons.length > table.perPage &&
            table.isPaginated
        "
        :per-page="table.perPage"
        :current-page.sync="table.currentPage"
        :pagination-simple="table.isPaginationSimple"
        :loading="loading"
        hoverable
        striped
        detail-key="_id"
        class="has-vertical-align-middle"
      >
        <template slot="empty">
          <no-results
            :cta="{ label: 'Add coupon' }"
            icon="ticket-alt"
            title="No coupons"
            message="This promotions doesn't have any coupons."
            @click="addCoupon"
          />
        </template>

        <template slot-scope="props">
          <b-table-column field="_id" label="Coupon code">
            <a
              class="has-text-dark"
              @click="$bus.$emit('copyToClipboard', `${props.row._id}`)"
            >
              <u>{{ props.row._id }}</u>
            </a>
          </b-table-column>

          <b-table-column label="Uses" width="100"
            >{{ props.row.useCount }} / {{ props.row.useLimit }}</b-table-column
          >

          <b-table-column label="Expires" width="160">
            <span v-if="props.row.dateExpires">
              {{
                $moment(props.row.dateExpires.toDate()).format("DD/MM/YY HH:mm")
              }}
            </span>
            <span v-else>--</span>
          </b-table-column>

          <b-table-column custom-key="edit" width="1">
            <button class="button" width="100" @click="edit(props.row)">
              Edit
            </button>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { collection, doc, orderBy, query, where } from "@firebase/firestore";
export default {
  name: "CouponsTable",
  props: {
    promotionId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      coupons: {},
      table: {
        isPaginated: true,
        isPaginationSimple: true,
        currentPage: 1,
        perPage: 10
      }
    };
  },
  computed: {
    sortedCoupons() {
      return this.$_(this.coupons)
        .orderBy(["dateCreated"], ["desc"])
        .toArray()
        .value();
    },
    oldestCoupon() {
      return this.$_.get(this, "sortedCoupons[0]", null);
    }
  },
  created() {
    this.getCoupons();
  },
  methods: {
    edit(coupon) {
      const modal = this.$modal.open({
        component: () => import("@shared/promotions/_couponModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          promotionId: this.promotionId,
          couponId: coupon._id
        },
        events: {
          success: () => {
            modal.close();
            this.getCoupons();
          }
        }
      });
    },
    addCoupon() {
      const modal = this.$modal.open({
        component: () => import("@shared/promotions/_couponModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          promotionId: this.promotionId
        },
        events: {
          success: () => {
            modal.close();
            this.getCoupons();
          }
        }
      });
    },
    getCoupons() {
      let ref = collection(this.$firestore, `coupons`);
      if (this.promotionId) {
        ref = query(
          ref,
          where(
            "promotionRef",
            "==",
            doc(this.$firestore, `promotions/${this.promotionId}`)
          )
        );
      }

      ref = query(ref, orderBy("dateCreated", "desc"));

      this.$store
        .dispatch(
          "pagination/getPaginated",
          { ref, limit: this.table.limit, cursor: this.oldestCoupon },
          { root: true }
        )
        .then(({ results }) => {
          this.loading = false;
          results.forEach(coupon => {
            this.$set(
              this.coupons,
              coupon.id,
              this.$_.merge({}, { _id: coupon.id }, coupon.data())
            );
          });
        })
        .catch(error => {
          console.error(error.message);
        });
    }
  }
};
</script>
